.member {
  &-container {
    background: #fafafa;
    border: 1px solid #d3d3d3;
    box-shadow: 0 6px 16px #2538581a, 0 6px 16px #2538581a;
    display: inline-block;
    width: 140px;
    height: 80px;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0 12px;
    transition: border box-shadow opacity 0.2s ease-in-out;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    box-shadow: inset 0 -4px 8px rgba(0, 0, 0, 0.2),
      inset 0 4px 8px rgba(0, 0, 0, 0.2); /* Add inner shadows here */

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &:hover {
      box-shadow: 0 6px 16px #2538581a, 0 0 0 1px #b3bac5;
    }
    &.selected {
      border: 2px solid #016d91;
      box-shadow: 0 6px 16px #2538581a, 0 0 0 1px #b3bac5;
    }
    &.selected > .member-count-wrapper {
      transform: scale(1) translateX(-50%);
    }
  }

  &-label {
    font-size: 14px;
    color: #000000;
    font-weight: 500;
  }
  &-count {
    font-size: 14px;
    &-icon {
      font-size: 12px;
      color: #fff;
      background-color: #016d91;
      border-radius: 50%;
      position: relative;
      width: 20px;
      height: 20px;
      cursor: pointer;

      & > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &-wrapper {
      display: flex;
      align-items: center;
      position: absolute;
      width: calc(100% - 10px);
      box-shadow: 0px 4px 20px 5px rgba(158, 158, 158, 0.25);
      border-radius: 17.5px;
      padding: 6px;
      transform: scale(0);
      transition: transform 0.2s ease-in-out;
      justify-content: space-between;
      bottom: -14px;
      left: 50%;
      background-color: #fff;
    }
  }
}

.stepper {
  &_wrapper {
    display: inline-flex;
    align-items: center;
    gap: 80px;
  }
  &_step {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #016d91;
    color: #016d91;
    font-weight: 500;
    position: relative;
    &:not(:last-child)::after {
      content: "";
      position: absolute;
      right: calc(-100% - 54px);
      width: 80px;
      height: 2px;
      background-color: #016d91;
    }
    &.active {
      background-color: #016d91;
      color: #fff;
    }
  }
}
